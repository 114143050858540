import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { SharedModule } from '../shared/shared.module';

import { BotService } from './bot/bot.service';
import { ConnectorService } from './connector/connector.service';
import { LookupService } from './lookup/lookup.service';

import { DialogService } from './dialog/dialog.service';
import { SignalRChannelService } from './signalrchannel/signalrchannel.service';
import { SignalRDebugService } from './signalrdebug/signalrdebug.service';

import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";

@NgModule({
  imports: [
    SharedModule,
    AuthModule,
    NgxSpinnerModule
  ],
  declarations: [
  ]
})

export class ChatStyleModule {
  static forRoot(): ModuleWithProviders<ChatStyleModule> {
    return {
      ngModule: ChatStyleModule,
      providers: [
        BotService,
        ConnectorService,
        LookupService,
        DialogService,
        SignalRChannelService,
        SignalRDebugService,
        NgxSpinnerService
      ]
    };
  }
}
