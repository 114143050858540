import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static settings: Map<string,string>;

  constructor(private http: HttpClient) {
  //  const m: Map<string, string> = new Map<string, string>();

  //  m.set('a', '1');
  //  m.set('b', '2');

  //  const str = JSON.stringify(m, this.replacer);
  }

  replacer(key, value) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
  }
  }

  reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  async load(): Promise<string> {
    const jsonFile = 'assets/config/config.' + environment.name + '.json';

    const promise = this.http.get<string>(jsonFile).toPromise();

    promise.then(entries => {
      AppConfigService.settings = new Map<string, string>();
      const keys = Object.keys(entries);
      keys.forEach(key => {
        AppConfigService.settings.set(key, entries[key]);
      });

    });

    return promise;
  }
}
