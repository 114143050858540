import { Injectable } from "@angular/core";
import { ExternalResource } from "../models/core.model";

declare var document: any;

@Injectable()
export class ExLoaderService {

  private resources: Map<string, ExternalResource> = new Map<string,ExternalResource>();

  constructor() {
  }

  load(res: ExternalResource[]) {
    let promises: any[] = [];
    res.forEach((r) => promises.push(this.loadResource(r)));
    return Promise.all(promises);
  }

  loadResource(r: ExternalResource) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      let er = this.resources.get(r.id);

      if (er && er.loaded) {
        resolve({ id: r.id, loaded: true, status: 'Already Loaded' });
      }
      else {
        if (!er) {
          r.loaded = false;
          this.resources.set(r.id, r);
        }
        //load it
        let newres = null;
        switch (r.type) {
          case 'script': {
            newres = document.createElement('script');
            newres.type = 'text/javascript';
            newres.src = r.src;
            }
            break;

          case 'style': {
            newres = document.createElement('link');
            newres.rel = 'stylesheet';
            newres.href = r.src;
          }
            break;
        }

        if (newres.readyState) {  //IE
          newres.onreadystatechange = () => {
            if (newres.readyState === "loaded" || newres.readyState === "complete") {
              newres.onreadystatechange = null;
              let er = this.resources.get(r.id);

              er.loaded = true;
              resolve({ id: r.id, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          newres.onload = () => {
            let er = this.resources.get(r.id);
            er.loaded = true;
            resolve({ id: r.id, loaded: true, status: 'Loaded' });
          };
        }
        newres.onerror = (error: any) => resolve({ id: r.id, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(newres);
      }
    });
  }

}
