import { Inject, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SignalRConnection } from '../models/signalr.model';
import { SubscribeResponse, Conversation, Message, UxItem, Attachment } from '../models/chatstyle.model';
import { environment } from '../../../environments/environment';

import { HubConnection } from '@microsoft/signalr';
import * as SignalR from '@microsoft/signalr';

@Injectable()
export class SignalRDebugService {

  constructor(private http: HttpClient) {
  }

  private hubConnection: HubConnection;
  public DebugMessageReceived: EventEmitter<DebugMessage> = new EventEmitter<DebugMessage>();
  public Subscribed: EventEmitter<SubscribeResponse> = new EventEmitter<SubscribeResponse>();

  private url: string = environment.signalrServer.url;
  private userId: string;
  private botId: string;
  private conversationId: string;
  private headers: HttpHeaders;


  init(id, bot, conversation) {

    this.userId = id + '-debug-' + conversation;
    this.botId = bot;
    this.conversationId = conversation;

    this.headers = new HttpHeaders().set('x-ms-signalr-userid', this.userId);
    if (conversation) {
      this.headers = this.headers.set('cv', this.conversationId);
    }

    this.stopConnection();

    this.getSignalRConnection().subscribe(con => {

      const options = {
        accessTokenFactory: () => con.accessToken
      };

      this.hubConnection = new SignalR.HubConnectionBuilder()
        .withUrl(con.url, options)
        .configureLogging(SignalR.LogLevel.Information)
        .build();

      this.hubConnection.start().catch(error => console.error(error));

      this.http.post<SubscribeResponse>(this.url + '/subscribe?botId=' + this.botId, '', { headers: this.headers })
        .subscribe(
          result => {
            console.log(result);
            if (!this.conversationId) {
              this.conversationId = result.conversationID;
            }
            //const resp: SubscribeResponse = {
            //  ConversationID: result.ConversationID,
            //  BotID: result.BotID,
            //  ImageUrl: result.ImageUrl
            //}
            this.Subscribed.emit(result);
          });


      this.hubConnection.on('debugRelay', data => {
        if (this.conversationId === data.Conversation.ConversationID) {
          console.log(data);
          const cv: Conversation = {
            botID: data.Conversation.BotID,
            conversationID: data.Conversation.ConversationID,
            active: data.Conversation.Active,
            status: data.Conversation.Status,
            state: data.Conversation.State,
            audienceChannelID: data.Conversation.audienceChannelID,
            message: null
          };
          if (data.Message) {
            const message: Message = {
              conversationID: data.Conversation.ConversationID,
              channel: data.Message.Channel,
              recipientID: data.Message.RecipientID,
              senderID: data.Message.SenderID,
              text: data.Message.Text,
              body: data.Message.Body,
              time: data.Message.Time,
              payload: data.Message.Payload,
              uxItems: [],
              attachments: [],
              fromDisplayName: '',
              toDisplayName: '',
              tag: '',
              info: '',
              blockInput: false,
              blockAttachments: false,
              ttl: ''
            };

            cv.message = message;
          }


          const msg: DebugMessage = {
            type: data.Type,
            conversation: cv
          };
          this.DebugMessageReceived.emit(msg);

        } 

      });

    });
  }

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop();
      this.hubConnection = null;
    }
  }

  private getSignalRConnection(): Observable<SignalRConnection> {
    return this.http.post<SignalRConnection>(
      this.url + '/negotiate', {}, { headers: this.headers }
    );
  }

}

export interface DebugMessage {
  type: string;
  conversation: Conversation;
};
