import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { AuthService } from '../auth/auth.service'
import { ProfileService, UserProfile } from '../profile/profile.service'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthResolverService implements Resolve<Promise<UserProfile>> {

  constructor(private http: HttpClient,
    private authService: AuthService,
    private profileService: ProfileService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UserProfile> {

    return this.profileService.load();
    
  }

}
