import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { AlertsService } from '../../chatstyle/alerts/alerts.service';
import { OrganizationService } from '../../chatstyle/organization/organization.service';
import { Organization } from '../../shared/models/core.model';

import { AuthService } from '../../auth/auth/auth.service';

import { UserAlert } from '../../shared/models/core.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;

  alerts: UserAlert[];

  public orgs: Organization[];
  public selectedOrg: Organization;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

  constructor(
    public userblockService: UserblockService, public settings: SettingsService, public injector: Injector,
    public alertsService: AlertsService,
    public authService: AuthService,
    public orgService: OrganizationService) {

    // show only a few items on demo
    this.orgs = [];
    this.selectedOrg = null;
    }

  logout() {
    this.authService.startLogOut();
  }

  ngOnInit() {
        this.isNavSearchVisible = false;

        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        //const el = this.fsbutton.nativeElement.firstElementChild;
        //screenfull.on('change', () => {
        //    if (el)
        //        el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        //});

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

    this.alertsService.getLast(3)
      .subscribe(
        result => {

          this.alerts = result;

        },
        error => console.error(error)
      );

    this.orgService.getOrganizations()
      .subscribe(
        result => {

          this.orgs = result;
          if (this.orgs) {
            if (this.orgs.length === 1) {
              this.selectedOrg = this.orgs[0];
            } else {
              for (let i = 0; i < this.orgs.length; i++) {
                if (this.orgs[i].active === true) {
                  this.selectedOrg = this.orgs[i];
                }
              }
              if (!this.selectedOrg) {
                this.selectedOrg = this.orgs[0];
              }
           }
           }
        },
        error => console.error(error)
      );
  }

  changeOrg(event) {
    console.log(event);

    let id: string = this.orgs[event.currentTarget.selectedIndex].id;

    this.orgService.setActive(id).then((result) => {
      this.router.navigate(['/bots'])
        .then(() => {
          window.location.reload();
        });
    });
  }

  activeAlerts() {
      let count = 0;

      if (this.alerts && this.alerts.length > 0) {
        for (let i = 0; i < this.alerts.length; i++) {
          if (!this.alerts[i].resolved) {
            count++;
          }
        }
      }

      return count;
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
  }


}
