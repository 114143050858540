import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientConfig } from '../models/clientconfig.model';
//import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigService {

  static settings: ClientConfig;

  constructor(private http: HttpClient) {
  }

  load(): Promise<ClientConfig>{
    const jsonFile = '/configuration';
    //const jsonFile = 'assets/config/clientconfig.' + environment.name + '.json';

    const promise = this.http.get<ClientConfig>(jsonFile).toPromise();

    promise.then(cfg => {
      ClientConfigService.settings = cfg;
    });

    return promise;
  }
}
