import { APP_INITIALIZER } from '@angular/core';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth-guard/auth-guard.service';
import { AuthLoadService } from './auth-load/auth-load.service';
import { AuthResolverService } from './auth-resolver/auth-resolver.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { SilentCallbackComponent } from './silent-callback/silent-callback.component';

import { MenuService } from './menu/menu.service';
import { ProfileService } from './profile/profile.service';
import { ClientConfigService } from './clientconfig/clientconfig.service';

export function initializeClient(
  clientConfig: ClientConfigService) {
  return () => {
    return clientConfig.load();
  }
}

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AuthCallbackComponent,
    SilentCallbackComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeClient,
      deps: [ClientConfigService],
      multi: true
    },
  //  AuthService,
  //  AuthLoadService,
  //  AuthGuardService,
  //  AuthResolverService,
  //  MenuService,
  //  ProfileService
  ],
  exports: [
    AuthCallbackComponent,
    SilentCallbackComponent
  ]
})

export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthLoadService,
        AuthGuardService,
        AuthResolverService,
        MenuService,
        ProfileService
      ]
    };
  }
}
