import { Component, OnInit, ViewChild, ElementRef, ɵSafeResourceUrl } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from '../../../shared/appconfig/appconfig.service'
import { Demo } from '../../../shared/models/demo.model'


@Component({
    selector: 'app-demo-demo',
    templateUrl: './demos.component.html',
  styleUrls: ['./demos.component.scss']
})
export class DemoComponent implements OnInit {
  public demoID: string;
  public safeUrl: SafeResourceUrl;
  public demo: Demo = null;
  protected headers: HttpHeaders;
  @ViewChild('demosite', { static: false }) demosite: ElementRef;

  constructor(
    private http: HttpClient,
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer) {

    this.demoID = this.actRoute.snapshot.params.id;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://chatstyle.ai/');

    this.http
      .get<Demo>(AppConfigService.settings.get('chatstyleapiServer') + 'demos/' + this.demoID,
        { headers: this.headers })
      .subscribe(
        result => {
          this.demo = result;
          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.demo.siteUrl);
        },
        error => console.error(error)
      );


  }

  ngOnInit() {


  }

  ngAfterViewInit() {

  }


}
