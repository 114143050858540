import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { AuthService } from '../auth/auth.service'
import { ProfileService } from '../profile/profile.service'
import { ClientConfigService } from '../clientconfig/clientconfig.service'
import { Router, Route, UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthLoadService implements CanLoad {

  constructor(private authService: AuthService,
    private profileService: ProfileService,
    private router: Router) {
}

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean> {

    return true;

  }
}
