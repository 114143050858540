import { APP_INITIALIZER } from '@angular/core';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { AuthModule } from '../auth/auth.module';
import { ChatStyleModule } from '../chatstyle/chatstyle.module';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { ApiModule } from './api/api.module';

import { routes } from './routes';

@NgModule({
    imports: [
        SharedModule,
        AuthModule,
        ChatStyleModule,
        RouterModule.forRoot(routes),
        PagesModule,
        ApiModule
    ],
    declarations: [],
    providers: [
    ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
  constructor(
    tr: TranslatorService) {
    }
}
