import { Component, OnInit, ViewChild, ElementRef, ɵSafeResourceUrl, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from '../../../shared/appconfig/appconfig.service'
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-bot-bottest',
    templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})
export class BotTestComponent implements OnInit {
  public channel: string;
  public dev: boolean;
  public safeUrl: SafeResourceUrl;
  public options: string;

  protected headers: HttpHeaders;
  @ViewChild('chatboxParent', { static: false }) chatboxParent: ElementRef;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer) {

    this.dev = (environment.chatboxenv === 'dev');
    this.channel = this.actRoute.snapshot.params.id;
    //this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://chatstyle.ai/');

    console.log('bottest');

    let env = "";
    if (environment.chatboxenv) {
      env = ", \"env\": \"" + environment.chatboxenv + "\" ";
    }
    this.options = "{ \"isOpen\": \"true\", \"loadHistory\": \"true\", \"autoStart\": \"true\" " + env + " }";
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    //this.chatbox.init(this.renderer, this.chatboxParent, this.channel);

    //const s = this.renderer.createElement('script');
    //s.type = 'text/javascript';
    //s.src = 'https://cdn.chatstyle.ai/chatbox/chatstyleai.js';
    //s.text = '';
    //if (s.readyState) {
    //  s.onreadystatechange = () => {
    //    if (s.readyState === "loaded" || s.readyState === "complete") {
    //      s.onreadystatechange = null;
    //      console.log('chatbox ready 1');
    //      this.loadChatbox();
    //    }
    //  };
    //} else {
    //  s.onload = () => {
    //    console.log('chatbox ready 2');
    //    this.loadChatbox();
    //  };
    //}

    //this.renderer.appendChild(this.chatboxParent.nativeElement, s);


  }

  //loadChatbox() {
  //  const chatbox = this.renderer.createElement('chatstyleai-chatbox');
  //  this.renderer.setAttribute(chatbox, 'id', 'chatbox');
  //  this.renderer.setAttribute(chatbox, 'channel', this.channel);
  //  this.renderer.setAttribute(chatbox, 'version', '');
  //  this.renderer.setAttribute(chatbox, 'options', '{ "isOpen": "false", "loadHistory": "true", "autoStart":"true", "createNew": "true" }');

  //  // TODO - Consider version in debugging?

  //  this.renderer.appendChild(this.chatboxParent.nativeElement, chatbox);

  //}


}
