import { Injectable } from '@angular/core';
import { UserProfile } from '../profile/profile.service'
import { SettingsService } from '../../core/settings/settings.service';

import { menu } from '../menu';
import { botMenu } from '../botmenu';
import { customerMenu } from '../customermenu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menuItems: Array<any>;
  botMenuItems: Array<any>;
  customerMenuItems: Array<any>;

  profile: UserProfile;

  constructor(private settings: SettingsService) {
      this.menuItems = [];
      this.botMenuItems = [];
    this.customerMenuItems = [];

    console.log('MenuService');
  }

  load(profile: UserProfile) {
    this.profile = profile;

    this.menuItems = [];
    this.botMenuItems = [];
    this.customerMenuItems = [];

    this.addMenu(menu);
    this.addBotMenu(botMenu);
    this.addCustomerMenu(customerMenu);
  }

  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    claim?: string,
    privilege?: string,
    submenu?: Array<any>
  }>) {
    items.forEach((item) => {
      let add = true;
      if (item.privilege) {
        add = false;
        let priv = item.privilege;
        if (item.privilege.startsWith("!")) {
          priv = item.privilege.substr(1);
        }
        if (this.profile && this.profile.privileges) {
          if (this.profile.privileges.indexOf(priv) > -1) {
            add = true;
          }
        }
        if (item.privilege.startsWith("!")) {
          add = !add;
        }
      }
      if (add) {
        this.menuItems.push(item);
        }
    });
  }

  addBotMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    claim?: string,
    privilege?: string,
    submenu?: Array<any>
  }>) {
    items.forEach((item) => {
      let add = true;
      if (item.privilege && this.profile && this.profile.privileges) {
        add = false;
        if (this.profile.privileges.indexOf(item.privilege) > -1) {
          add = true;
        }
      }
      if (add) {
        this.botMenuItems.push(item);
      }
    });
  }

  addCustomerMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    claim?: string,
    privilege?: string,
    submenu?: Array<any>
  }>) {
    items.forEach((item) => {
      let add = true;
      if (item.privilege && this.profile && this.profile.privileges) {
        add = false;
        if (this.profile.privileges.indexOf(item.privilege) > -1) {
          add = true;
        }
      }
      if (add) {
        this.customerMenuItems.push(item);
      }
    });
  }

  getMenu() {
    return this.menuItems;
  }

  hasPrivilege(priv: string): boolean {
    if (this.profile) {
      if (this.profile.privileges !== null && this.profile.privileges.indexOf(priv) >= 0) {
        return true;
      }
    }
    return false;
  }


  getBotMenu(botID: string, state: string, configurationType: string) {
    const clonedArray = [];
    const values = this.settings;
    this.botMenuItems.forEach(function (value) {
      const clone = Object.assign({}, value);
      if (value.link) {
        clone.link = value.link.replace(":id", botID);
        clone.link = clone.link.replace(":type", configurationType);
      }
      if (value.elink) {
        clone.elink = value.elink.replace(":help_uri", values.getSetting('help_uri'));
      }
      if (!clone.state || clone.state === state) {
        clonedArray.push(clone);
      }
    });

    return clonedArray;
  }

  getCustomerMenu(botID: string, state: string, configurationType: string) {
    const clonedArray = [];
    const values = this.settings;
    this.customerMenuItems.forEach(function (value) {
      const clone = Object.assign({}, value);
      if (value.link) {
        clone.link = value.link.replace(":id", botID);
        clone.link = clone.link.replace(":type", configurationType);
      }
      if (value.elink) {
        clone.elink = value.elink.replace(":help_uri", values.getSetting('help_uri'));
      }
      if (!clone.state || clone.state === state) {
        clonedArray.push(clone);
      }
    });

    return clonedArray;
  }

}
