
const Back = {
  text: 'Main Menu',
  link: '/bots',
  icon: 'icon-arrow-left'
};

const BotGeneral = {
  text: 'General',
  link: '/bots/:id/general',
  icon: 'icon-info'
};

const BotResources = {
  text: 'Resources',
  link: '/bots/:id/resources',
  icon: 'icon-notebook'
};

const BotVisuals = {
  text: 'Visual',
  link: '/bots/:id/visuals',
  icon: 'icon-star',
  //state: 'ready'
};

const BotSecurity = {
  text: 'Security',
  link: '/bots/:id/roles',
  icon: 'icon-key',
  privilege: 'Admin'
};

const BotAudience = {
  text: 'Audience',
  link: '/bots/:id/audience',
  icon: 'icon-microphone',
  state: 'ready'
};

const BotChannels = {
  text: 'Channels',
  link: '/bots/:id/channels',
  icon: 'icon-globe'
};

const BotDialogs = {
  text: 'Dialogs',
  link: '/bots/:id/dialogs',
  icon: 'icon-speech',
  privilege: 'Dialog'
};

const BotDialogEvents = {
  text: 'Dialog Events',
  link: '/bots/:id/dialogevents',
  icon: 'icon-pencil',
  privilege: 'Dialog'
};

const BotConversations = {
  text: 'Conversations',
  link: '/bots/:id/conversations',
  icon: 'icon-bubbles',
  //privilege: 'Admin'
};


const BotConfiguration = {
  text: 'Settings',
  link: '/bots/:id/configuration',
  icon: 'icon-settings',
  state: 'setup'
};

const BotAdminConfiguration = {
  text: 'Admin',
  link: '/bots/:id/configuration/admin',
  icon: 'icon-briefcase',
  privilege: 'SuperAdmin'
};

const BotOrganization = {
  text: 'Organization',
  link: '/organization',
  icon: 'icon-organization',
  state: 'ready'
};

const BotOrgUsers = {
  text: 'Users',
  link: '/organization/users',
  icon: 'icon-user'
};

const BotTriggers = {
  text: 'Triggers',
  link: '/bots/:id/triggers',
  icon: 'icon-energy',
  privilege: 'Admin'
};

const BotCommands = {
  text: 'Commands',
  link: '/bots/:id/commands',
  icon: 'icon-magic-wand',
  privilege: 'Admin'
};

const BotForms = {
  text: 'Forms',
  link: '/bots/:id/forms',
  icon: 'icon-list',
  privilege: 'Forms'
};

const BotActions = {
  text: 'Actions',
  link: '/bots/:id/actions',
  icon: 'icon-target',
  privilege: 'Admin'
};

const BotQueries = {
  text: 'Queries',
  link: '/bots/:id/queries',
  icon: 'icon-target',
  privilege: 'Admin'
};

const BotTags = {
  text: 'Tags',
  link: '/bots/:id/tags',
  icon: 'icon-tag'
};

const BotConnectors = {
  text: 'Connectors',
  link: '/bots/:id/connectors',
  icon: 'icon-link',
  privilege: 'Admin'
};

const BotBuild = {
  text: 'Build',
  link: '/forms',
  icon: 'icon-wrench',
  privilege: 'Admin'
};

const BotHelp = {
  text: 'Help',
  elink: ':help_uri',
  target: 'blank',
  icon: 'icon-info'
};

const BotDialogOverrides = {
  text: 'Overrides',
  link: '/bots/:id/overrides',
  icon: 'icon-layers',
  privilege: 'Admin'
};

const BotReports = {
  text: 'Reports',
  link: '/bots/:id/reports',
  icon: 'icon-grid',
  privilege: '',
  state: 'ready'
};

const Forms = {
    text: 'Forms',
    link: '/forms',
    icon: 'icon-note',
    submenu: [
        {
            text: 'Standard',
            link: '/forms/standard'
        },
        {
            text: 'Extended',
            link: '/forms/extended'
        },
        {
            text: 'Validation',
            link: '/forms/validation'
        },
        {
            text: 'Upload',
            link: '/forms/upload'
        },
        {
            text: 'Image Crop',
            link: '/forms/cropper'
        }
    ]
};



const botHeadingMain = {
  text: 'Bot Menu',
  heading: true
};

const BotConfigurationByType = {
  text: 'Settings',
  link: '/bots/:id/configuration/:type',
  icon: 'icon-settings',
  state: 'ready'
};

const BotWatchers = {
  text: 'Watchers',
  link: '/bots/:id/watchers',
  icon: 'icon-eye',
  privilege: 'Admin'
};


export const botMenu = [
  botHeadingMain,
  Back,
  BotAdminConfiguration,
  BotGeneral,
  BotActions,
  BotChannels,
  BotCommands,
  BotConnectors,
  BotDialogs,
  //BotDialogEvents,
  //BotConversations,
  BotDialogOverrides,
  BotForms,
  //BotAudience,
  BotOrganization,
  BotQueries,
  BotReports,
  BotResources,
  BotSecurity,
  BotConfiguration,
  BotConfigurationByType,
  BotTags,
  BotTriggers,
  BotOrgUsers,
  BotVisuals,
  BotWatchers,
  BotHelp
];
