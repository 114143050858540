<div class="wrapper">
    <div class="abs-center">
        <div class="text-center my-3">
            <h1 class="mb-3">
                <sup>
                </sup>
                <em class="fa fa-check-circle fa-3x text-muted text-purple"></em>
            </h1>
            <div class="text-bold text-lg mb-3">{{title}}</div>
            <p class="lead m0">{{message}}</p>
            <a href="{{downloadUrl}}">{{downloadText}}</a>
        </div>
    </div>
</div>
