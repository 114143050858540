export const environment = {
  name: "devpub",
  production: true,
  "signalrServer": {
    "url": "https://devchatbox.chatstyle.ai/api"
  },
  chatboxenv: "dev",
  stripe: {
    key: "pk_test_51ItJYGAjP2XCQIBG9x37ZLbzQBDj4iFIMnqUdV8HMU7wePH6ljp4nFR6CM6QkX8lMoDFTilCqEbgEYeGia0nR54o00Qk2pbgK5"
  }
};
