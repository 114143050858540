import { Inject, Injectable, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth/auth.service';
import { AppConfigService } from '../../shared/appconfig/appconfig.service'
import { Observable } from 'rxjs';

import { NameValueDescription } from '../../shared/models/core.model';
import {
  Bot, BotTemplate, BotCommand, BotChannel, BotAudience, BotAudienceChannel, BotConversationMap,
  BotConnectorQuery, BotTrigger, BotRole, BotConnectorAction, BotSetting, BotSetupOptions,
  BotDialogOverride, BotChannelWatcher, BotChannelWatcherAction
} from '../models/bot.model';
import { Connector, ConnectorDefinition } from '../models/connector.model';
import { Conversation, Message, UxItem, Attachment, BotVisuals } from '../models/chatstyle.model';
import { Series, ToDo } from '../../shared/models/core.model';
import { DialogEvent } from '../models/dialogs.model';

@Injectable({
  providedIn: 'root'
})
export class BotService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') baseUrl: string) {
  }

  private headers: HttpHeaders;

  getUrl(): string {
    return AppConfigService.settings.get('chatstyleapiServer');
  }

  emptyBot(): Bot {
    return {
      botID: '',
      description: '',
      audienceCount: 0,
      imageUrl: '',
      name: '',
      active: true,
      category: '',
      state: '',
      configurationType: '',
      maintenanceDelay: 0,
      timeZoneOffset: -300,
      dialogOwnerId: ''
    };
  };

  emptyMessage(conversationID: string, botID: string, senderID: string): Message {

    if (this.authService) {
      if (this.authService.isLoggedIn()) {
        senderID = this.authService.getClaims().sub;
      }
    }

    return {
      attachments: [],
      body: "",
      channel: "signalr",
      conversationID: conversationID,
      recipientID: botID,
      senderID: senderID,
      payload: "",
      text: "",
      time: new Date(),
      uxItems: [],
      fromDisplayName: '',
      toDisplayName: '',
      tag: '',
      info: '',
      blockInput: false,
      blockAttachments: false,
      ttl: ''
    };
  }

  newId(): Observable<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get(this.getUrl() + 'bots/newid', { headers: this.headers, responseType: 'text' });

  }

  getBots(): Promise<Bot[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Bot[]>(this.getUrl() + 'bots', { headers: this.headers }).toPromise();

  }

  getBotTemplates(): Promise<BotTemplate[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotTemplate[]>(this.getUrl() + 'bots/templates', { headers: this.headers }).toPromise();

  }

  getBot(id: string): Promise<Bot> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Bot>(this.getUrl() + 'bots/' + id,
      { headers: this.headers }).toPromise();

  }

  getBotConfiguration(id: string): Promise<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<string>(this.getUrl() + 'bots/' + id + '/configuration',
      { headers: this.headers }).toPromise();

  }

  setBotConfiguration(id: string, config: any): Promise<string> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<string>(this.getUrl() + 'bots/' + id + '/configuration',
        config,
        { headers: this.headers }).toPromise();
  }


  getBotAdminConfiguration(id: string): Promise<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<string>(this.getUrl() + 'bots/' + id + '/admin/configuration',
      { headers: this.headers }).toPromise();

  }

  setBotAdminConfiguration(id: string, config: any): Promise<string> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<string>(this.getUrl() + 'bots/' + id + '/admin/configuration',
        config,
        { headers: this.headers }).toPromise();
  }
  setBotDialogRoot(id: string, dialogGraphId:string): Promise<Bot> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<Bot>(this.getUrl() + 'bots/' + id + '/dialogroot/' + dialogGraphId,
        dialogGraphId,
        { headers: this.headers }).toPromise();
  }

  getApiKey(id: string): Promise<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<string>(this.getUrl() + 'bots/' + id + '/apiKey',
      { headers: this.headers }).toPromise();

  }

  getSettings(id: string): Promise<BotSetting[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotSetting[]>(this.getUrl() + 'bots/' + id + '/settings',
      { headers: this.headers }).toPromise();

  }

  getSetup(id: string): Promise<ToDo[]> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<ToDo[]>(this.getUrl() + 'bots/' + id + '/setup',
      { headers: this.headers }).toPromise();

  }

  getUserSettings(id: string): Promise<BotSetting[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotSetting[]>(this.getUrl() + 'bots/' + id + '/usersettings',
      { headers: this.headers }).toPromise();

  }

  getMetrics(id: string, days:number): Promise<Series[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Series[]>(this.getUrl() + 'bots/' + id + '/metrics?days=' + days.toString(),
      { headers: this.headers }).toPromise();

  }

  getVisuals(id: string): Promise<BotVisuals> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotVisuals>(this.getUrl() + 'bots/' + id + '/visuals',
      { headers: this.headers }).toPromise();

  }

  getRoles(id: string): Promise<BotRole[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotRole[]>(this.getUrl() + 'bots/' + id + '/roles',
      { headers: this.headers }).toPromise();

  }

  getTriggers(id: string): Promise<BotTrigger[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotTrigger[]>(this.getUrl() + 'bots/' + id + '/triggers',
      { headers: this.headers }).toPromise();

  }

  getDialogEvents(id: string): Promise<DialogEvent[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<DialogEvent[]>(this.getUrl() + 'bots/' + id + '/dialogevents',
      { headers: this.headers }).toPromise();

  }

  updateDialogEvents(id: string, events: DialogEvent[]): Promise<DialogEvent[]> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .post<DialogEvent[]>(this.getUrl() + 'bots/' + id + '/dialogevents',
        events,
        { headers: this.headers }).toPromise();
  }

  getActions(id: string): Promise<BotConnectorAction[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotConnectorAction[]>(this.getUrl() + 'bots/' + id + '/actions',
      { headers: this.headers }).toPromise();

  }

  getQueries(id: string): Promise<BotConnectorQuery[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotConnectorQuery[]>(this.getUrl() + 'bots/' + id + '/queries',
      { headers: this.headers }).toPromise();

  }

  getChannels(id: string): Promise<BotChannel[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotChannel[]>(this.getUrl() + 'botchannel/' + id,
      { headers: this.headers }).toPromise();

  }

  getChannelConnectors(id: string, channel: string): Promise<Connector[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Connector[]>(this.getUrl() + 'botchannel/' + id + '/connectors/' + channel,
      { headers: this.headers }).toPromise();

  }

  getCommands(id: string): Promise<BotCommand[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotCommand[]>(this.getUrl() + 'botcommand/' + id,
      { headers: this.headers }).toPromise();

  }



  getWatchers(id: string): Promise<BotChannelWatcher[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotChannelWatcher[]>(this.getUrl() + 'watcher/' + id,
      { headers: this.headers }).toPromise();

  }

  getDialogOverrides(id: string): Promise<BotDialogOverride[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotDialogOverride[]>(this.getUrl() + 'botdialogoverride/' + id,
      { headers: this.headers }).toPromise();

  }

  getConnectors(id: string): Promise<ConnectorDefinition[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });


    return this.http.get<ConnectorDefinition[]>(this.getUrl() + 'bots/' + id + '/connectors',
      { headers: this.headers }).toPromise();

  }

  getConnector(id: string): Promise<ConnectorDefinition> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });


    return this.http.get<ConnectorDefinition>(this.getUrl() + 'connectors/' + id,
      { headers: this.headers }).toPromise();

  }

  getConnectorsByType(id: string, type: string, subtype: string, availableFor: string): Promise<ConnectorDefinition[]> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    let avail = '';
    if (availableFor) {
      avail = '?available=' + availableFor;
    }

    return this.http.get<ConnectorDefinition[]>(this.getUrl() + 'bots/' + id + '/connectors/' + type + '/' + subtype + avail,
      { headers: this.headers }).toPromise();

  }

  getAvailableConnectorsByType(id: string, type: string, subtype: string): Promise<ConnectorDefinition[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });


    return this.http.get<ConnectorDefinition[]>(this.getUrl() + 'bots/' + id + '/connectors/available/' + type + '/' + subtype,
      { headers: this.headers }).toPromise();

  }

  getConversations(id: string): Promise<Conversation[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Conversation[]>(this.getUrl() + 'bots/' + id + '/conversations',
      { headers: this.headers }).toPromise();

  }

  getChannel(botid: string, id: string): Promise<BotChannel> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotChannel>(this.getUrl() + 'botchannel/' + botid + '/' + id,
      { headers: this.headers }).toPromise();

  }

  getChannelContent(channel: string, id: string): Promise<string> {

    return this.http.get(this.getUrl() + 'botchannel/content/' + channel + '/' + id,
       { responseType: 'text' }).toPromise();

  }

  getCommand(botid: string, command: string): Promise<BotCommand> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotCommand>(this.getUrl() + 'botcommand/' + botid + '/' + command,
      { headers: this.headers }).toPromise();

  }

  getWatcher(botid: string, id: string): Promise<BotChannelWatcher> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotChannelWatcher>(this.getUrl() + 'watcher/' + botid + '/' + id,
      { headers: this.headers }).toPromise();

  }

  getDialogOverride(botid: string, overrideId: string): Promise<BotDialogOverride> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotDialogOverride>(this.getUrl() + 'botdialogoverride/' + botid + '/' + overrideId,
      { headers: this.headers }).toPromise();

  }

  deleteChannel(botid: string, id: string): Observable<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.delete(this.getUrl() + 'botchannel/' + botid + '/' + id,
      { headers: this.headers, responseType: 'text' });

  }

  deleteCommand(botid: string, command: string): Observable<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.delete(this.getUrl() + 'botcommand/' + botid + '/' + command,
      { headers: this.headers, responseType: 'text' });

  }

  deleteWatcher(botid: string, id: string): Observable<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.delete(this.getUrl() + 'watcher/' + botid + '/' + id,
      { headers: this.headers, responseType: 'text' });

  }

  deleteDialogOverride(botid: string, overrideId: string): Observable<string> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.delete(this.getUrl() + 'botdialogoverride/' + botid + '/' + overrideId,
      { headers: this.headers, responseType: 'text' });

  }

  emptyChannel(): BotChannel {

    const channel: BotChannel = {
      botID: '',
      botChannelID: '',
      name: '',
      description: '',
      active: true,
      channel: '',
      channelUniqueID: '',
      connectorID: '',
      data: '',
      role: '',
      maintenanceDelay: 0,
      options: '',
      companyName: '',
      fromTemplate: false,
      channelContent: '',
      isVerified: false
    };
    return channel;
  }

  emptyRole(): BotRole {
    const r: BotRole = {
      targetDialogGraphID: '',
      targetDialogNodeID: '',
      role: '',
      note: '',
      description: '',
      conditionScript: '',
      active: true,
      initialState: '',
      aiEnabled: false
    };
    return r;
  };

  emptyCommand(): BotCommand {

    const cmd: BotCommand = {
      botID: '',
      name: '',
      description: '',
      active: true,
      language: 'javascript',
      role: '',
      commandVersion: '',
      helpText: '',
      sampleScript: '',
      commandScript: '',
      fromTemplate: false,
      global: false,
      readOnly: false,
      visible: true,
      scriptVisible: true,
      parametersJson: '',
      shortName: '',
      addToPSA: false,
      category: ''
    };
    return cmd;
  }


  emptyQuery(): BotConnectorQuery {

    const q: BotConnectorQuery = {
      name: '',
      connectorID: '',
      connectorDefinitionID: '',
      botID: '',
      returnType: '',
      queryType: '',
      queryScript: '',
      orderBy: '',
      active: true,
      cacheExpiry: 60,
      uniqueID: ''
    };
    return q;
  }


  emptyWatcher(): BotChannelWatcher {

    const w: BotChannelWatcher = {
      botID: '',
      internalID: '',
      externalID: '',
      name: '',
      description: '',
      actions: [],
      actionsJson: '',
      connectorUserID: '',
      connectorID: '',
      connectorDefinitionID: '',
      connectorExternalID: '',
      watchBotChannelId: '',
      watchGroupId: '',
      watchGroupName: '',
      watchChannelId: '',
      watchChannelName: '',
      watchThreadId: '',
      synch: false,
      synchChannel: '',
      synchBotChannelId: '',
      synchGroupId: '',
      synchGroupName: '',
      synchChannelId: '',
      synchChannelName: '',
      active: true,
      readOnly: false,
      ignoreBotMentions: true,
      channelToWatch: null,
      teamToWatch: null,
      channelToSynch: null,
      teamToSynch: null
    };
    return w;
  }

  emptyWatcherAction(): BotChannelWatcherAction {

    const w: BotChannelWatcherAction = {
      Action: '',
      ActionQueue: '',
      Tag: '',
      Behavior: '',
      Data: '',
      UseConversation: true,
      Active: true,
      Match: []
    };
    return w;
  }

  emptyDialogOverride(): BotDialogOverride {

    const ovr: BotDialogOverride = {
      botID: '',
      overrideID: '',
      name: '',
      description: '',
      active: true,
      role: '',
      script: '',
      shortName: ''
    };
    return ovr;
  }

  emptyAudience(): BotAudience {

    const ba: BotAudience = {
      botID: '',
      audienceID: '',
      firstName: '',
      lastName: '',
      profilePicUrl: '',
      email: '',
      phone: '',
      hasLocation: false,
      latitude: -1,
      longitude: -1,
      role: ''
    };
    return ba;
  }

  emptyAction(): BotConnectorAction {

    const a: BotConnectorAction = {
      name: '',
      connectorID: '',
      connectorDefinitionID: '',
      action: '',
      type: '',
      actionScript: '',
      conditionScript: '',
      active: true,
      language: 'javascript'
    };
    return a;
  }

  emptyVisuals(): BotVisuals {

    const v: BotVisuals = {
      label: '',
      description: '',
      imageUrl: '',
      iconUrl: '',
      primaryColor: '',
      secondaryColor: '',
      frameColor: ''
    };
    return v;
  }

  emptyAudienceChannel(): BotAudienceChannel {

    const bac: BotAudienceChannel = {
      botID: '',
      audienceID: '',
      active: false,
      channel: '',
      channelUniqueID: '',
      botChannelUniqueID: '',
      name: '',
      isParent: false
    };
    return bac;
  }

  getAudience(id: string): Promise<BotAudience[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotAudience[]>(this.getUrl() + 'bots/' + id + '/audience',
      { headers: this.headers }).toPromise();

  }

  editAudience(botID: string, id: string): Promise<BotAudience> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotAudience>(this.getUrl() + 'bots/' + botID +
      '/audience/' + id,
      { headers: this.headers }).toPromise();

  }


  editAction(botID: string, name: string): Promise<BotConnectorAction> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotConnectorAction>(this.getUrl() + 'bots/' + botID +
      '/actions/' + name,
      { headers: this.headers }).toPromise();

  }

  editQuery(botID: string, name: string): Promise<BotConnectorQuery> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotConnectorQuery>(this.getUrl() + 'bots/' + botID +
      '/queries/' + name,
      { headers: this.headers }).toPromise();

  }


  setBot(bot: Bot): Promise<Bot> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<Bot>(this.getUrl() + 'bots/' + bot.botID,
        bot,
        { headers: this.headers }).toPromise();
  }

  setAudience(audience: BotAudience): Promise<BotAudience> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotAudience>(this.getUrl() + 'bots/' + audience.botID,
        audience,
        { headers: this.headers }).toPromise();
  }

  setAction(botId: string, action: BotConnectorAction): Promise<BotConnectorAction> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotConnectorAction>(this.getUrl() + 'bots/' + botId + '/actions',
        action,
        { headers: this.headers }).toPromise();
  }

  setChannel(channel: BotChannel): Promise<BotChannel> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotChannel>(this.getUrl() + 'botchannel/' + channel.botID,
        channel,
        { headers: this.headers }).toPromise();
  }

  setCommand(command: BotCommand): Promise<BotCommand> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotCommand>(this.getUrl() + 'botcommand/' + command.botID,
        command,
        { headers: this.headers }).toPromise();
  }

  setWatcher(watcher: BotChannelWatcher): Promise<BotChannelWatcher> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotChannelWatcher>(this.getUrl() + 'watcher/' + watcher.botID,
        watcher,
        { headers: this.headers }).toPromise();
  }

  setQuery(botId: string, query: BotConnectorQuery): Promise<BotConnectorQuery> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotConnectorQuery>(this.getUrl() + 'bots/' + botId + '/queries',
        query,
        { headers: this.headers }).toPromise();
  }

  setDialogOverride(dovr: BotDialogOverride): Promise<BotDialogOverride> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotDialogOverride>(this.getUrl() + 'botdialogoverride/' + dovr.botID,
        dovr,
        { headers: this.headers }).toPromise();
  }

  setSettings(id: string, settings: BotSetting[]): Promise<BotSetting[]> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotSetting[]>(this.getUrl() + 'bots/' + id + '/settings',
        settings,
        { headers: this.headers }).toPromise();
  }

  setRoles(id: string, settings: BotRole[]): Promise<BotRole[]> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotRole[]>(this.getUrl() + 'bots/' + id + '/roles',
        settings,
        { headers: this.headers }).toPromise();
  }

  setVisuals(id: string, visuals: BotVisuals): Promise<BotVisuals> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<BotVisuals>(this.getUrl() + 'bots/' + id + '/visuals',
        visuals,
        { headers: this.headers }).toPromise();
  }

  /*
  getSupport(id: string): Promise<BotAudienceChannel[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotAudienceChannel[]>(this.getUrl() + 'bots/' + id +
      '/support/all',
      { headers: this.headers }).toPromise();

  }

  setSupport(audience: BotAudienceChannel): Promise<BotAudienceChannel> {
    return this.http
      .post<BotAudienceChannel>(this.getUrl() + 'bots/' + audience.botID +
        '/support/all',
        audience,
        { headers: this.headers }).toPromise();
  }
  */

  createBot(options: BotSetupOptions): Promise<Bot> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .put<Bot>(this.getUrl() + 'bots/', options, { headers: this.headers })
      .toPromise();
  }

  getMaps(id: string): Promise<BotConversationMap[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<BotConversationMap[]>(this.getUrl() + 'bots/' + id + '/conversationmaps',
      { headers: this.headers }).toPromise();

  }


  updateMaps(id: string, maps: BotConversationMap[]): Promise<BotConversationMap[]> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .post<BotConversationMap[]>(this.getUrl() + 'bots/' + id + '/conversationmaps',
        maps,
        { headers: this.headers }).toPromise();
  }


  resetConversation(channel: string): Promise<Object> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .post(this.getUrl() + 'conversation/' +
        channel + "/signalr/" + this.authService.getClaims().sub + "/reset",
        "",
        { headers: this.headers }).toPromise();
  }

  restartConversation(channel: string): Promise<Object> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .post(this.getUrl() + 'conversation/' +
        channel + "/signalr/" + this.authService.getClaims().sub + "/restart",
        "",
        { headers: this.headers }).toPromise();
  }

  debugConversation(channel: string): Promise<Object> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .post(this.getUrl() + 'conversation/' +
        channel + "/signalr/" + this.authService.getClaims().sub + "/debug/true",
        "",
        { headers: this.headers }).toPromise();
  }

  setConnector(botId: string, connector: Connector): Promise<Connector> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<Connector>(this.getUrl() + 'bots/' + botId + '/connectors',
        connector,
        { headers: this.headers }).toPromise();
  }


}


