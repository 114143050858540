import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ClientConfigService } from '../clientconfig/clientconfig.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private manager: UserManager = null;
  private user: User = null;
  private route: ActivatedRouteSnapshot = null;
  private router: Router = null;

  constructor(router: Router, private http: HttpClient) {
    this.router = router;

    this.getManager();
  }

  isLoggedIn(): boolean {
    return this.user !== null && !this.user.expired;
  }

  logout() {
    this.manager.signoutRedirect();
  }

  getClaims(): any {
    return this.user.profile;
  }

  hasClaim(claim: string): boolean {
    return ((this.user !== null) && (this.user.profile != null) && (claim in this.user.profile));
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  getManager() {
    if (this.manager !== null) return this.manager;

    this.manager = new UserManager(getClientSettings(ClientConfigService.settings));
    this.manager.events.addAccessTokenExpired(_ => {
      //console.log(this.user);
      console.log('token expired');
      //window.location.reload();
      this.manager.signinSilent().then(user => {

        console.log('token refreshed');
        this.user = user;
      });
    });

    this.manager.getUser().then(user => {
      this.user = user;
    });

    return this.manager;
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
  }

  startAuthentication(route: RouterStateSnapshot) {
    const url = route.url.toString();

    const mgr = this.getManager();

    mgr.signinRedirect({ state: url });
  }

  completeAuthentication() {
    const mgr = this.getManager();

    return mgr.signinRedirectCallback().then(user => {
      this.user = user;

      const url = this.user.state;
      const failurl = '/'; //ClientConfigService.settings.post_logout_redirect_uri;
      console.log('navigate to ' + this.user);

      this.router.navigateByUrl(url).then(e => {
        console.log(e);
        if (e) {
        } else {
          console.log('redirect to ' + failurl);
          this.router.navigateByUrl(failurl);
        }
      }, (reason) => {
          console.log('rejected');
          console.log(reason);
          console.log('redirect to ' + failurl);
          this.router.navigateByUrl(failurl);
      });
    });
  }


  completeSilentSignin() {
    const mgr = this.getManager();

    return mgr.signinSilentCallback().then(user => {

      console.log('silent signin complete');
    });
  }
  startLogOut() {
    if (this.isLoggedIn()) {
      const mgr = this.getManager();

      return mgr.signoutRedirect();
    }
  }

  completeLogOut() {
    const mgr = this.getManager();

    return mgr.signoutRedirectCallback().then(user => {
      this.user = null;
    });
  }

}

export function getClientSettings(cfg): UserManagerSettings {
  return {
    authority: cfg.authority,
    client_id: cfg.client_id,
    redirect_uri: cfg.redirect_uri,
    post_logout_redirect_uri: cfg.post_logout_redirect_uri,
    response_type: cfg.response_type,
    scope: cfg.scope,
    automaticSilentRenew: cfg.automaticSilentRenew,
    silent_redirect_uri: cfg.silent_redirect_uri
  };
}
