import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';

import { LabComponent } from './lab/lab.component';
import { DemoComponent } from './demos/demos.component';
import { BotTestComponent } from './bot/bot.component';
import { BotAppComponent } from './botapp/botapp.component';
import { BotTeamsTabComponent } from './teamstab/teamstab.component';
import { ChatStyleModule } from '../../chatstyle/chatstyle.module';

/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        SharedModule,
      ChatStyleModule,
        // RouterModule.forChild(routes)
    ],
    declarations: [
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
      LabComponent,
      DemoComponent,
      BotTestComponent,
      BotAppComponent,
      BotTeamsTabComponent
    ],
    exports: [
        RouterModule,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
      LabComponent,
      DemoComponent,
      BotTestComponent,
      BotAppComponent,
      BotTeamsTabComponent
    ]
})
export class PagesModule { }
