
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

//const Dashboard = {
//    text: 'Dashboard',
//    link: '/dashboard',
//    icon: 'icon-speedometer',
//    privilege: 'User',
//    submenu: [
//        {
//            text: 'Dashbord v1',
//            link: '/dashboard/v1'
//        },
//        {
//            text: 'Dashbord v2',
//            link: '/dashboard/v2'
//        },
//        {
//            text: 'Dashbord v3',
//            link: '/dashboard/v3'
//        }
//    ]
//};
const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard/gettingstarted',
  icon: 'icon-speedometer',
  privilege: 'User'
};

const Alerts = {
  text: 'Alerts',
  link: '/alerts',
  icon: 'icon-bell',
  privilege: 'User'
};


const Widgets = {
    text: 'Widgets',
    link: '/widgets',
    icon: 'icon-grid',
    privilege: 'Nobody'
};

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        },
        {
            text: 'Interaction',
            link: '/elements/interaction'
        },
        {
            text: 'Notification',
            link: '/elements/notification'
        },
        {
            text: 'SweetAlert',
            link: '/elements/sweetalert'
        },
        {
            text: 'Spinners',
            link: '/elements/spinners'
        },
        {
            text: 'Dropdown',
            link: '/elements/dropdown'
        },
        {
            text: 'Nav Tree',
            link: '/elements/navtree'
        },
        {
            text: 'Sortable',
            link: '/elements/sortable'
        },
        {
            text: 'Grid',
            link: '/elements/grid'
        },
        {
            text: 'Grid Masonry',
            link: '/elements/gridmasonry'
        },
        {
            text: 'Typography',
            link: '/elements/typography'
        },
        {
            text: 'Font Icons',
            link: '/elements/iconsfont'
        },
        {
            text: 'Weahter Icons',
            link: '/elements/iconsweather'
        },
        {
            text: 'Colors',
            link: '/elements/colors'
        },
        {
            text: 'Infinite Scroll',
            link: '/elements/infinitescroll'
        }
    ]
};

const Forms = {
    text: 'Forms',
    link: '/forms',
    icon: 'icon-note',
    submenu: [
        {
            text: 'Standard',
            link: '/forms/standard'
        },
        {
            text: 'Extended',
            link: '/forms/extended'
        },
        {
            text: 'Validation',
            link: '/forms/validation'
        },
        {
            text: 'Upload',
            link: '/forms/upload'
        },
        {
            text: 'Image Crop',
            link: '/forms/cropper'
        }
    ]
};

const Charts = {
    text: 'Charts',
    link: '/charts',
    icon: 'icon-graph',
    submenu: [
        {
            text: 'Flot',
            link: '/charts/flot'
        },
        {
            text: 'Radial',
            link: '/charts/radial'
        },
        {
            text: 'ChartJS',
            link: '/charts/chartjs'
        }
    ]
};

const Tables = {
    text: 'Tables',
    link: '/tables',
    icon: 'icon-grid',
    submenu: [
        {
            text: 'Standard',
            link: '/tables/standard'
        },
        {
            text: 'Extended',
            link: '/tables/extended'
        },
        {
            text: 'Angular Grid',
            link: '/tables/aggrid'
        },
        {
            text: 'NGxDatatables',
            link: '/tables/ngxdatatable'
        }
    ]
};

const Maps = {
    text: 'Maps',
    link: '/maps',
    icon: 'icon-map',
    submenu: [
        {
            text: 'Google',
            link: '/maps/google'
        },
        {
            text: 'Vector',
            link: '/maps/vector'
        }
    ]
};

const Pages = {
    text: 'Pages',
    link: '/pages',
  icon: 'icon-doc',
  privilege: 'Developer',
    submenu: [
        //{
        //    text: 'Login',
        //    link: '/login'
        //},
        //{
        //    text: 'Register',
        //    link: '/register'
        //},
        //{
        //    text: 'Recover',
        //    link: '/recover'
        //},
        //{
        //    text: 'Lock',
        //    link: '/lock'
        //},
        //{
        //    text: '404',
        //    link: '/404'
        //},
        //{
        //    text: '500',
        //    link: '/500'
        //},
        //{
        //    text: 'Maintenance',
        //    link: '/maintenance'
        //},
        {
          text: 'Lab',
          link: '/lab'
        }
    ]
};

const Blog = {
    text: 'Blog',
    link: '/blog',
    icon: 'icon-notebook',
    submenu: [
        {
            text: 'List',
            link: '/blog/list'
        },
        {
            text: 'Post',
            link: '/blog/post'
        },
        {
            text: 'Articles',
            link: '/blog/articles'
        },
        {
            text: 'Article View',
            link: '/blog/articleview'
        }
    ]
}

const Ecommerce = {
    text: 'Ecommerce',
    link: '/ecommerce',
    icon: 'icon-basket-loaded',
    submenu: [
        {
            text: 'Orders',
            link: '/ecommerce/orders'
        },
        {
            text: 'Order View',
            link: '/ecommerce/orderview'
        },
        {
            text: 'Products',
            link: '/ecommerce/products'
        },
        {
            text: 'Product View',
            link: '/ecommerce/productview'
        },
        {
            text: 'Checkout',
            link: '/ecommerce/checkout'
        }
    ]
}

const Extras = {
    text: 'Extras',
    link: '/extras',
    icon: 'icon-cup',
    submenu: [
        {
            text: 'Contacts',
            link: '/extras/contacts'
        },
        {
            text: 'Contact details',
            link: '/extras/contactdetails'
        },
        {
            text: 'Projects',
            link: '/extras/projects'
        },
        {
            text: 'Projects details',
            link: '/extras/projectsdetails'
        },
        {
            text: 'Team Viewer',
            link: '/extras/teamviewer'
        },
        {
            text: 'Social Board',
            link: '/extras/socialboard'
        },
        {
            text: 'Vote links',
            link: '/extras/votelinks'
        },
        {
            text: 'Bug tracker',
            link: '/extras/bugtracker'
        },
        {
            text: 'Faq',
            link: '/extras/faq'
        },
        {
            text: 'Help center',
            link: '/extras/helpcenter'
        },
        {
            text: 'Followers',
            link: '/extras/followers'
        },
        {
            text: 'Settings',
            link: '/extras/settings'
        },
        {
            text: 'Plans',
            link: '/extras/plans'
        },
        {
            text: 'File manager',
            link: '/extras/filemanager'
        },
        {
            text: 'Forum',
            link: '/extras/forum'
        },
        {
            text: 'Mailbox',
            link: '/extras/mailbox'
        },
        {
            text: 'Timeline',
            link: '/extras/timeline'
        },
        {
            text: 'Calendar',
            link: '/extras/calendar'
        },
        {
            text: 'Invoice',
            link: '/extras/invoice'
        },
        {
            text: 'Search',
            link: '/extras/search'
        },
        {
            text: 'Todo list',
            link: '/extras/todolist'
        },
        {
            text: 'Profile',
            link: '/extras/profile'
        },
        {
            text: 'Code editor',
            link: '/extras/codeeditor'
        }
    ]
}

const Api = {
    text: 'Api Demo',
    link: '/api/weather',
    icon: 'icon-target'
};

const headingMain = {
    text: 'Main Menu',
    heading: true
};

const headingComponents = {
    text: 'Components',
    heading: true
};

const headingMore = {
    text: 'More',
    heading: true
};

const BotHelp = {
  text: 'Help',
  elink: ':help_uri',
  target: 'blank',
  icon: 'icon-info'
};

const Bots = {
  text: 'Bots',
  link: '/bots',
  icon: 'icon-bubbles',
  privilege: 'User'
};

const Connectors = {
  text: 'Connectors',
  link: '/connectors',
  icon: 'icon-link',
  privilege: 'User'
};


const Onboarding = {
  text: 'Setup',
  link: '/setup',
  icon: 'icon-power',
  privilege: 'Onboarding',
  submenu: [
    {
      text: 'Profile',
      link: '/setup/profile'
    },
    //{
    //  text: 'Bot',
    //  link: '/setup/bot'
    //},
    //{
    //  text: 'Tickets',
    //  link: '/setup/tickets'
    //},
    //{
    //  text: 'Channels',
    //  link: '/setup/channels'
    //},
    //{
    //  text: 'Payment',
    //  link: '/setup/billing'
    //},
    //  {
    //    text: 'Activate',
    //    link: '/setup/activate'
    //  },
  ]
};

export const menu = [
    headingMain,
    //Home,
    Dashboard,
    Bots,
    Connectors,
    //Alerts,
    Onboarding,
    BotHelp,
//    Widgets,
//    headingComponents,
//    Elements,
//    Forms,
//    Charts,
//    Tables,
//    Maps,
//    headingMore,
    Pages,
//    Blog,
//    Ecommerce,
//    Extras,
//    Api
];
