import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service'
import { AppConfigService } from '../../shared/appconfig/appconfig.service'
import { UserProfile } from '../../shared/models/core.model'
import { MenuService } from '../menu/menu.service'
import { forEach } from 'lodash';

export { UserProfile } from '../../shared/models/core.model'

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public profile: UserProfile;

  constructor(
    private http: HttpClient, private authService: AuthService,
    private menuService: MenuService,
    @Inject('BASE_URL') baseUrl: string) {

    this.profile = {
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      status: 'profile',
      privileges: ['Onboarding'],
      clientJson: '',
      customerId: '',
      paymentMethodId: '',
      email: '',
      country: '',
      postalCode: ''
    };

  }

  isLoaded(): boolean {
    if (this.profile) {
      return true;
    }

    return false;
  }

  hasPrivilege(priv: string): boolean {
    if (this.profile) {
      if (this.profile.privileges !== null && this.profile.privileges.indexOf(priv) >= 0) {
        return true;
      }
    }
    return false;
  }

  hasAnyPrivilege(priv: string[]): boolean {
    for (var i = 0; i < priv.length; i++) {
      let p = priv[i];
      if (this.hasPrivilege(p)) {
        return true;
      }
    }

    return false;
  }

  isOnboarding(): boolean {
    if (this.profile) {
      if (this.profile.privileges !== null && this.profile.privileges.indexOf('Onboarding') >= 0) {
        return true;
      }
    }
    return false;
  }

  isPending(): boolean {
    if (this.profile) {
      if (this.profile.privileges !== null && this.profile.privileges.indexOf('Pending') >= 0) {
        return true;
      }
    }
    return false;
  }

  isBeta(): boolean {
    if (this.profile) {
      if (this.profile.privileges !== null && this.profile.privileges.indexOf('Beta') >= 0) {
        return true;
      }
    }
    return false;
  }

  isActive(): boolean {
    if (this.profile) {
      if (this.profile.status === 'active') return true;
    }
    return false;
  }

  getStatus(): string {
    if (this.profile) {
      if (this.profile.status) return this.profile.status;
    }
    return 'profile';
  }

  async load(): Promise<UserProfile> {
    if (this.authService.isLoggedIn() === false) {
      return new Promise((resolve, reject) => {
        resolve(this.profile);
      });
    }

    const headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    const url = AppConfigService.settings.get('apiServer');

    const promise = this.http.get<UserProfile>(url + 'profile', { headers: headers }).toPromise();

    promise.then(cfg => {
      this.profile = cfg;
      console.log(this.profile);

      if (this.profile.privileges.length === 0) {
        this.profile.privileges.push('Onboarding');
      }

      this.menuService.load(this.profile);

    });

    return promise;

  }

  async save(p: UserProfile): Promise<UserProfile> {
    if (this.authService.isLoggedIn() === false) {
      return new Promise((resolve, reject) => {
        resolve(this.profile);
      });
    }

    const headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    const url = AppConfigService.settings.get('apiServer');

    const promise = this.http.post<UserProfile>(url + 'profile', p, { headers: headers }).toPromise();

    promise.then(cfg => {
      this.profile = cfg;
      console.log(this.profile);

      this.menuService.load(this.profile);
    });

    return promise;
  }

}


