import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
  private layout: any;
  public settings: any;

  constructor(private http: HttpClient) {

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'CloudRadial Chat',
            description: 'Portal',
            year: ((new Date()).getFullYear()),
            version: '2.0.20241110ng18'
      };

      this.settings = {};

    console.log('settings...');
      const jsonFile = '/settings';

      const promise = this.http.get(jsonFile).toPromise();

    promise.then(cfg => {
      console.log('settings=' + cfg);
        this.settings = cfg;
      });

      // User Settings
      // -----------------------------------
      this.user = {
        name: 'John',
        job: 'ng-developer',
        picture: 'assets/img/user/02.jpg'
      };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            nodeSidebarOpen: false,
            linkSidebarOpen: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

  }

  getSetting(name) {
    return name ? this.settings[name] : this.settings;
  }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

}
