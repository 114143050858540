import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() {
  }

  getCookie(name: string): string {

    const value = "; " + document.cookie;

    const parts = value.split("; " + name + "=");

    if (parts.length === 2) {

      return parts.pop().split(";").shift();

    }

    return null;
  }

  setCookie(name: string, value: string, days: number) {

    const date = new Date();

    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }
}


