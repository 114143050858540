<div class="chatstyleai-styles" *ngIf="bot && isVisible">
  <div id="messageBubble" class="chatbox-bubble" *ngIf="showPop && (expandedMode === false)"
       [style.right]="bubbleXPos"
       [style.bottom]="bubbleYPos">
    <p (click)="toggleChat()" [innerHTML]="newMessage.text"></p>
  </div>
  <div id="chatstyleai-chatbox-window" #chatbox class="chatbox-window container-fluid rounded"
       [class.chatpopup]="isPopup"
       [class.chatbox-window-expanded]="expandedMode"
       [class.chatmobile]="isMobile"
       [class.chatmobilesmall]="isMobileSmall"
       [@openCloseChat]="isOpen ? 'open' : 'closed'"
       [class.chatclosed]="!isOpen" [class.chatopen]="isOpen"
       [style.right]="windowXPos"
       [style.bottom]="windowYPos"
       [style.z-index]="getz()"
       [style.height]="windowHeightVal">
    <div class="chatstyleai-styles card" id="chatbox-window-body">
      <div class="chatbox-header-bar"
           [class.chatboxheaderapp]="isApp"
           [style.background-color]="visuals.frameColor">
        <div class="chatbox-header-close pull-right">
          <div *ngIf="!isApp" class="xbutton" id="xbutton" (click)="toggleChat()" style="margin-top: 5px"></div>
          <!--<img src="https://chatstyleelements.thoughtpost.com/assets/img/close-white.png" height="32px" (click)="toggleChat()" />-->
        </div>
        <div id="chatbox-header-name" class="chatbox-header-name">{{visuals.label}}</div>
      </div>
      <div id="chatbox-overlay"
           [style.display]="connected() ? 'none' : 'block'"
           [@connection]="connected() ? 'inactive' : 'active'">
        <ngx-spinner [fullScreen]="false" type="ball-pulse-sync" size="medium">
          <a class="chatbox-loading" (click)="reconnect()" [innerHTML]="connectionMessage()"></a>
        </ngx-spinner>
      </div>
      <div class="card-body" id="chatbox-window-messages"
           [class.chatboxwindowmessagesapp]="isApp"
           [class.chatbox-disabled]="!connected()">
        <div class="chatbox-window-row row">
          <div class="col-md-12 chatbox-full-height chatbox-full-width">
            <div class="chatbox-window-main chatbox-full-height"
                  [class.chatbox-window-main-expanded]="expandedMode"
                 [class.chatmobilewindow]="isMobile">
              <div class="chatbox-window-history" #historyScroll>
                <div id="promptbox" class="chatbox-prompt" style="padding: 0 20px 20px 20px;" *ngIf="expandedMode">
                  <div class="row">
                    <div class="col-12 text-center" style="padding-top: 50px">
                      <img width="80px" class="chatstyleai-styles rounded-circle"
                          src="{{visuals.imageUrl}}">
                      <br>
                      <div class="text-center">
                        <h3>{{visuals.label}}</h3>
                      </div>
                      <div style="margin-bottom: 30px;">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="card-deck">
                      <div class="card col-4">
                        <div class="card-body">
                          <strong>Having an issue?</strong>
                          <br><br>
                          I want to report an issue
                        </div>
                      </div>

                      <div class="card col-4">
                        <div class="card-body">
                          <strong>Checking on an issue?</strong>
                          <br><br>
                          I want an update on my tickets
                        </div>
                      </div>
                      <div class="card col-4">
                        <div class="card-body">
                          <strong>Check for live person?</strong>
                          <br><br>
                          I want to reach an online tech if they are available.

                        </div>
                      </div>
                      <div class="card col-4">
                        <div class="card-body">
                          <strong>4th option</strong>
                          <br><br>
                          Other stuff here

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="chatbox-messages">
                  <li class="clearfix" *ngFor="let message of messages; last as lastMessage">
                    <div class="message-data"
                         [class.my-message-data]="message.senderID === audienceID"
                         [class.other-message-data]="message.senderID !== audienceID"
                         *ngIf="message.payload !== 'message.typing'">
                      <span *ngIf="message.fromDisplayName" class="message-data-name"> {{message.fromDisplayName}}</span>
                      <span class="message-data-time">{{message.time | messageTime}}</span>
                    </div>
                    <div class="message" [class.my-message]="message.senderID === audienceID"
                         [class.other-message]="message.senderID !== audienceID"
                         [ngStyle]="getStyles(message)">
                      <div class="message-text" *ngIf="message.text" [innerHtml]="message.text + '<br />'"></div>
                      <div *ngIf="message.attachments && message.attachments.length > 0">
                        <div class="message-attachment" *ngFor="let att of message.attachments" (click)="getAttachment(att)">
                          <span>{{att.name}}</span>
                        </div>
                      </div>

                      <div class="typing-indicator" *ngIf="message.payload === 'message.typing'">
                        <span></span><span></span><span></span>
                      </div>
                    </div>
                    <div *ngIf="message.uxItems && message.uxItems.length > 0 && lastMessage">
                      <ng-container *ngFor="let item of message.uxItems">
                        <div *ngIf="item.type === 'button'" class="message-choice"
                             [ngStyle]="getStyles(null)"
                             (click)="sendOption(item)">
                          <span>{{item.label}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </li>
                </ul>
                <div id="chatbox-lastError" *ngIf="lastErrorText" [innerHTML]="lastErrorText"></div>
              </div>
              <div class="chatbox-cmdprompt" *ngIf="prompt">
                <span class="chatbox-cmdprompt-text" [class.chatbox-cmdprompt-text-mobile]="isMobile">{{prompt}}</span>
                <button *ngIf="promptMode !== 'ok'" class="mb-sm btn btn-sm btn-oval btn-primary chatbox-cmdprompt-btn" id="btn-promptno"
                        [style.border-color]="visuals.primaryColor"
                        [style.background-color]="visuals.secondaryColor"
                        (click)="answerPrompt('no')" type="button">
                  No
                </button>
                <button *ngIf="promptMode !== 'ok'" class="mb-sm btn btn-sm btn-oval btn-primary chatbox-cmdprompt-btn" id="btn-promptyes"
                        [style.border-color]="visuals.primaryColor"
                        [style.background-color]="visuals.secondaryColor"
                        (click)="answerPrompt('yes')" type="button">
                  Yes
                </button>
                <button *ngIf="promptMode === 'ok'" class="mb-sm btn btn-sm btn-oval btn-primary chatbox-cmdprompt-btn" id="btn-promptyes"
                        [style.border-color]="visuals.primaryColor"
                        [style.background-color]="visuals.secondaryColor"
                        (click)="answerPrompt('ok')" type="button">
                  OK
                </button>
              </div>
              <div class="chatbox-window-message clearfix" *ngIf="!prompt"
                   [class.chatbox-disabled]="!connected()">
                <input #messageInput class="chatbox-input form-control rounded"
                       *ngIf="getInputType() === 'text'"
                       [value]="outMessage.text"
                       (input)="outMessage.text = $event.target.value"
                       [style.border-color]="visuals.frameColor"
                       [readonly]="blockInput()"
                       [placeholder]="getPlaceholder()"
                       (keyup.enter)="send()" />
                <input #messageInput class="chatbox-input form-control rounded"
                       *ngIf="getInputType() === 'date'"
                       bsDatepicker
                       [bsValue]="dateValue"
                       (bsValueChange)="onDateValueChange($event)"
                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM-DD-YYYY' }"
                       [container]="'div#chatbox-window-messages'"
                       [placement]="'top'"
                       [style.border-color]="visuals.frameColor"
                       [placeholder]="getPlaceholder()"
                       (keyup.enter)="send()" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chatstyleai-styles card-footer" id="chatbox-window-footer" *ngIf="connected()">
        <button class="mb-sm btn btn-oval btn-primary btn-send" id="btn-chat"
                [style.border-color]="visuals.primaryColor"
                [style.background-color]="visuals.secondaryColor"
                [style.cursor]="canSend() ? 'pointer' : 'not-allowed'"
                [disabled]="!canSend()"
                (click)="send()" type="button" style="float: left; margin-right: 10px">
          SEND
        </button>
        <div class="camerabutton" title="Upload"
             [class.cameramobile]="isMobile"
             [style.background-color]="canUpload() ? visuals.frameColor : '#333'"
             [style.cursor]="canUpload() ? 'pointer' : 'not-allowed'"
             *ngIf="fileToUpload === null" (click)="addAttachment()" style="float: left; margin-top: 5px; margin-right: 10px"></div>
        <div class="resetbutton" title="Start Over"
             [style.background-color]="canRestart() ? visuals.frameColor : '#333'"
             (click)="restart()" style="float: left; margin-top: 5px; margin-right: 10px"></div>
        <div class="screenshotbutton" title="Screenshot"
             *ngIf="canScreenshot()"
             [style.background-color]="canScreenshot() ? visuals.frameColor : '#333'"
             (click)="screenshot()" style="float: left; margin-top: 5px; margin-right: 10px"></div>
        <div class="upload-attachment" *ngIf="fileToUpload" (click)="clearAttachment()">
          <span>{{getFileToUpload()}}</span>
        </div>
        <div class="countdown-display" *ngIf="countdownTo"
             [style.color]="getCountdownColor(countdownTo)"
             [style.text-shadow]="getCountdownTextShadow(countdownTo)">

          <!--<span class="countdownTo">{{countdownTo | countdown}}</span>-->
        </div>
        <input #fileUpload class="chatbox-upload"
               [readonly]="blockInput()"
               (change)="uploadChange()" type="file" />
      </div>
    </div>
  </div>
  <img id="chatstyleai-chatbox-button" #chatboxbutton  *ngIf="bot && isVisible && (isPopup === false) && (expandedMode === false)"
       class="chatstyleai-styles rounded-circle chatbox-button"
       [class.chatboxbuttonmobile]="isMobile"
       [style.bottom]="buttonYPos" [style.right]="buttonXPos"
       alt="Avatar" src="{{visuals.imageUrl}}" (click)="toggleChat()" />
  <canvas id="ec" style="display:none"></canvas>
</div>
