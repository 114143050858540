import { Inject, Injectable, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../auth/auth/auth.service';
import { AppConfigService } from '../../shared/appconfig/appconfig.service'

import { UserAlert } from '../../shared/models/core.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') baseUrl: string) {
  }

  private headers: HttpHeaders;

  getActive() {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<UserAlert[]>(AppConfigService.settings.get('chatstyleapiServer') + 'alerts/active', { headers: this.headers });

  }

  getLast(count) {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<UserAlert[]>(AppConfigService.settings.get('chatstyleapiServer') + 'alerts/last/' + count,
      { headers: this.headers });

  }

  getAll() {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<UserAlert[]>(AppConfigService.settings.get('chatstyleapiServer') + 'alerts/active', { headers: this.headers });

  }



}


