import { Component, OnInit, ViewChild, ElementRef, ɵSafeResourceUrl, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from '../../../shared/appconfig/appconfig.service'
import { environment } from '../../../../environments/environment';
import {
  ChatstyleaiChatboxComponent
} from 'chatstyleai-chatbox';

@Component({
    selector: 'app-bot-botapp',
    templateUrl: './botapp.component.html',
  styleUrls: ['./botapp.component.scss']
})
export class BotAppComponent implements OnInit {
  public channel: string;
  public dev: boolean;
  public safeUrl: SafeResourceUrl;
  public optionsList: string;

  protected headers: HttpHeaders;
  @ViewChild('chatboxParent', { static: false }) chatboxParent: ElementRef;
  @ViewChild(ChatstyleaiChatboxComponent) chatbox: ChatstyleaiChatboxComponent;
  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer) {

    this.dev = (environment.chatboxenv === 'dev');
    //this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://chatstyle.ai/');

    console.log('botapp');

    let env = "";
    let auth = "";
    let email = this.actRoute.snapshot.queryParamMap.get('contact_email');

    let options:any = {};
    options.isOpen = "true";
    options.isPopup = "true";
    options.isApp = "true";
    options.allowScreenshot = "true";
    options.autoStart = "true";
    options.createNew = "true";

    if (email) {
      options.authRequired = "true";
      auth = "\"authRequired\": \"true\", ";
    }
    if (environment.chatboxenv) {
      options.env = environment.chatboxenv;
    }
    this.optionsList = JSON.stringify(options);
    console.log(this.optionsList);


    this.channel = this.actRoute.snapshot.params.id;
    //"{ \"isOpen\": \"true\", \"isPopup\": \"true\", \"isApp\": \"true\", \"allowScreenshot\": \"true\", " + auth + "\"loadHistory\": \"true\", \"autoStart\": \"true\" " + env + " }";
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    let email = this.actRoute.snapshot.queryParamMap.get('contact_email');
    
    if (email) {
      console.log('email=' + email);
      //let chatbox:any = document.querySelector('#chatbox');
      let userInfo: any = {};
      userInfo.email = email;
      userInfo.displayName = email;

      //console.log(chatbox);
      console.log(userInfo);
      this.chatbox.userInfo = userInfo;
    }


    //this.chatbox.init(this.renderer, this.chatboxParent, this.channel);

    //const s = this.renderer.createElement('script');
    //s.type = 'text/javascript';
    //s.src = 'https://cdn.chatstyle.ai/chatbox/chatstyleai.js';
    //s.text = '';
    //if (s.readyState) {
    //  s.onreadystatechange = () => {
    //    if (s.readyState === "loaded" || s.readyState === "complete") {
    //      s.onreadystatechange = null;
    //      console.log('chatbox ready 1');
    //      this.loadChatbox();
    //    }
    //  };
    //} else {
    //  s.onload = () => {
    //    console.log('chatbox ready 2');
    //    this.loadChatbox();
    //  };
    //}

    //this.renderer.appendChild(this.chatboxParent.nativeElement, s);


  }

  //loadChatbox() {
  //  const chatbox = this.renderer.createElement('chatstyleai-chatbox');
  //  this.renderer.setAttribute(chatbox, 'id', 'chatbox');
  //  this.renderer.setAttribute(chatbox, 'channel', this.channel);
  //  this.renderer.setAttribute(chatbox, 'version', '');
  //  this.renderer.setAttribute(chatbox, 'options', '{ "isOpen": "false", "loadHistory": "true", "autoStart":"true", "createNew": "true" }');

  //  // TODO - Consider version in debugging?

  //  this.renderer.appendChild(this.chatboxParent.nativeElement, chatbox);

  //}


}
