import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-lab',
    templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss']
})
export class LabComponent implements OnInit {
  public code: string;
  public script: string;


  constructor() {

    this.code = 'CODE A';

    this.script = 'SCRIPT X';

  }

  ngOnInit() {


  }

  ngAfterViewInit() {

  }

  onInit(editor) {
  };


}
