import { Inject, Injectable, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../auth/auth/auth.service';
import { AppConfigService } from '../../shared/appconfig/appconfig.service'

import { Lookup } from '../../shared/models/core.model';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') baseUrl: string) {
  }

  private headers: HttpHeaders;

  getGroup(group) {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Lookup[]>(AppConfigService.settings.get('chatstyleapiServer') + 'lookups/' + group, { headers: this.headers });

  }



}


