import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NowDirective } from './directives/now/now.directive';

import { SignalRChannelService } from '../services/signalrchannel.service';
import { CookieService } from '../services/cookie.service';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
      SignalRChannelService,
      CookieService
    ],
    declarations: [
      NowDirective,
   ],
    exports: [
        CommonModule,
        RouterModule,
        NowDirective
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
