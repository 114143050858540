import { Inject, Injectable, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../auth/auth/auth.service';
import { AppConfigService } from '../../shared/appconfig/appconfig.service'

import { Organization } from '../../shared/models/core.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') baseUrl: string) {
  }

  private headers: HttpHeaders;

  public emptyOrg: Organization = {
    name: '',
    active: true,
    tier: '',
    phone: '',
    email: '',
    country: '',
    postalCode: '',
    crmId: '',
    billingId: '',
    supportId: '',
    id: '',
    readOnly: false
  };

  empty(): Organization {
    return this.emptyOrg;
  }

  getOrganizations() {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Organization[]>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/',
      { headers: this.headers });

  }

  getMyOrganization(): Promise<Organization> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Organization>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/me',
      { headers: this.headers }).toPromise();;

  }

  updateOrganization(org: Organization) {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<string>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/' + org.id,
        org,
        { headers: this.headers }).toPromise();

  }

  getUsers(): Promise<Organization[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Organization[]>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/users',
      { headers: this.headers }).toPromise();

  }

  getInvitations(): Promise<Organization[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Organization[]>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/invitations',
      { headers: this.headers }).toPromise();

  }

  getMyInvitations(): Promise<Organization[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Organization[]>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/invitations/me',
      { headers: this.headers }).toPromise();

  }

  setActive(id: string): Promise<any> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<string>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/' + id + '/active',
        {},
        { headers: this.headers }).toPromise();
  }

  inviteUser(email: string): Promise<any> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<string>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/invite/',
        {"email": email},
        { headers: this.headers }).toPromise();
  }


  updateUserActivation(org: Organization): Promise<Organization> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<Organization>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/users',
        org,
        { headers: this.headers }).toPromise();

  }

  updateInvitationActivation(org: Organization): Promise<Organization> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<Organization>(AppConfigService.settings.get('chatstyleapiServer') + 'organization/invitations',
        org,
        { headers: this.headers }).toPromise();

  }


}


