import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ChatStyleModule } from './chatstyle/chatstyle.module';

import { DirtyGuardService } from './shared/dirty-guard/dirty-guard.service';

import { NgxStripeModule } from 'ngx-stripe';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ],
  imports: [
    NgxSpinnerModule,
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule.forRoot(),
        AuthModule.forRoot(),
        ChatStyleModule.forRoot(),
        SharedModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        LayoutModule,
    RoutesModule,
    NgxStripeModule.forRoot(environment.stripe.key)
    ],
  providers: [
    NgxSpinnerService,
    DirtyGuardService
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
