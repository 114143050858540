import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { TranslatorService } from './translator/translator.service';

import { throwIfAlreadyLoaded } from './module-import-guard';

@NgModule({
  imports: [
  ],
  providers: [
    TranslatorService,
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        SettingsService,
      ]
    };
  }
}
