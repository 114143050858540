import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service'
import { ProfileService } from '../profile/profile.service'
import { ClientConfigService } from '../clientconfig/clientconfig.service'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,
    private profileService: ProfileService,
    private router: Router) {
}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|Promise<boolean> {
    if (this.authService.isLoggedIn()) {

      const failurl = ClientConfigService.settings.post_logout_redirect_uri;
      console.log(failurl);

      if ("claims" in route.data) {
        const claims = route.data.claims as Array<string>;
        const userClaims = this.authService.getClaims();

        for (let index = 0; index < claims.length; ++index) {
          if (claims[index] in userClaims) {
            if ("value" in route.data) {
              if (userClaims[claims[index]] !== route.data.value) {

                this.router.navigateByUrl(failurl);

                return false;
              }
            }
            return true;
          }
        }

        this.router.navigateByUrl(failurl);
        return false;
      }


      if ("privilege" in route.data) {

        return new Promise<boolean>((resolve, reject) => {
          this.profileService.load().then((pro) => {

            const priv = route.data.privilege;
            if (this.profileService.profile && this.profileService.profile.privileges) {
              if (this.profileService.profile.privileges) {
                const userPrivs = this.profileService.profile.privileges;

                if (userPrivs !== null && userPrivs.indexOf(priv) >= 0) {
                  return resolve(true);
                }
              }

            }

            let privfail = failurl;
            if (this.profileService.isOnboarding()) {
              privfail = failurl + '/setup/profile';
            }
            this.router.navigateByUrl(privfail);

            return resolve(false);

          }, (reason) => {
              return resolve(false);
          });


        });
      }

      return true;

    }

    this.authService.startAuthentication(state);
    return false;
  }
}
