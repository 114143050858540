import { Component, OnInit, ViewChild, ElementRef, ɵSafeResourceUrl, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { AppConfigService } from '../../../shared/appconfig/appconfig.service'
import { environment } from '../../../../environments/environment';
import { app, Context } from '@microsoft/teams-js';
import { BotService } from '../../../chatstyle/bot/bot.service'

@Component({
    selector: 'app-bot-teamstab',
    templateUrl: './teamstab.component.html',
    styleUrls: ['./teamstab.component.scss']
})
export class BotTeamsTabComponent implements OnInit {
  public content: SafeHtml;
  public appid: string;

  protected headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private actRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private botService: BotService) {

    this.content = "";
    this.appid = this.actRoute.snapshot.params.id;

    console.log('teamstab');

  }

  ngOnInit() {

    console.log(this.appid);

    if (this.appid.indexOf(":") > 0) {

      this.botService.getChannelContent('msteams', this.appid).then((result) => {
        this.content = this.sanitizer.bypassSecurityTrustHtml(result);
        //this.content = result;
      }, (err) => {
        console.error(err);
      });


    } else {

      app.initialize().then((result) => {

        app.getContext().then((context) => {
          console.log(context.app.appLaunchId);
          console.log(context.user.tenant.id);

          let channelid = this.appid + ':' + context.user.tenant.id;

          this.botService.getChannelContent('msteams', channelid).then((result) => {
            this.content = this.sanitizer.bypassSecurityTrustHtml(result);
            //this.content = result;
          }, (err) => {
            console.error(err);
          });

        }, (err) => {
          console.error(err);
        });

      }, (err) => {
        console.error(err);

      });

    }




  }

  ngAfterViewInit() {

  }


}
