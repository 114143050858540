import { Inject, Injectable, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth/auth.service'
import { AppConfigService } from '../../shared/appconfig/appconfig.service'

import { Connector, ConnectorDefinition } from '../models/connector.model';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') baseUrl: string) {
  }


  getUrl(): string {
    return AppConfigService.settings.get('chatstyleapiServer');
  }

  private headers: HttpHeaders;

  public connectorDef: ConnectorDefinition = {
    connectorDefinitionID: '',
    connectorID: '',
    type: '',
    connectorType: '',
    cardImageUrl: '',
    cardStyle: '',
    iconName: '',
    description: '',
    imageUrl: '',
    name: '',
    active: true,
    testUrl: '',
    siteUrl: '',
    companyID: '',
    companyName: '',
    publicKey: '',
    privateKey: '',
    hostName: '',
    port: 0,
    secure: false,
    userName: '',
    password: '',
    secret: '',
    appID: '',
    userID: '',
    scriptObjectTypeName: '',
    typeName: '',
    token: '',
    lastVerified: '',
    data: '',
    subscribe: false,
    fromTemplate: false,
    manifestUrl: '',
    canTest: false
  };

  empty(): ConnectorDefinition {
    return this.connectorDef;
  }

  get(): Promise<Connector[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Connector[]>(this.getUrl() + 'connectors',
      { headers: this.headers }).toPromise();

  }

  enableConnectors(botId: string): Promise<Object> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.post(this.getUrl() + 'connectors/bot/' + botId, {},
      { headers: this.headers }).toPromise();

  }

  deleteConnectors(botId: string): Promise<Object> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.delete(this.getUrl() + 'connectors/bot/' + botId,
      { headers: this.headers }).toPromise();

  }

  deleteConnector(connectorID: string): Promise<Object> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.delete(this.getUrl() + 'connectors/' + connectorID,
      { headers: this.headers }).toPromise();

  }

  getDefinitions(group: string): Promise<Connector[]> {


    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<Connector[]>(this.getUrl() + 'connectors/definitions/' + group,
      { headers: this.headers }).toPromise();

  }

  getConnector(id: string): Promise<ConnectorDefinition> {

    const url = 'connectors/' + id;

    return this.getInternal(url);
  }

  getNew(type: string, id: string): Promise<ConnectorDefinition> {

    const url = 'connectors/new/' + type + '/' + id;

    return this.getInternal(url);
  }

  set(connector: ConnectorDefinition): Promise<ConnectorDefinition> {

    const url = 'connectors/' + connector.connectorID;

    return this.setInternal(url, connector);
  }

  test(connector: ConnectorDefinition): Promise<ConnectorDefinition> {

    const url = 'connectors/' + connector.connectorID + '/test';

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<ConnectorDefinition>(this.getUrl() + url,
      { headers: this.headers }).toPromise();
  }

  activate(connector: ConnectorDefinition, botId: string, state: string): Promise<ConnectorDefinition> {

    const url = 'connectors/' + connector.connectorID + '/activate/' + state;

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<ConnectorDefinition>(this.getUrl() + url,
      { headers: this.headers }).toPromise();
  }

  newConnector(connector: ConnectorDefinition): Promise<ConnectorDefinition> {

    const url = 'connectors/new/' + connector.connectorType + '/' + connector.connectorDefinitionID;

    return this.setInternal(url, connector);
  }

  getInternal(url: string): Promise<ConnectorDefinition> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.get<ConnectorDefinition>(this.getUrl() + url,
      { headers: this.headers }).toPromise();

  }

  setInternal(url: string, connector: ConnectorDefinition): Promise<ConnectorDefinition> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http.post<ConnectorDefinition>(this.getUrl() + url,
      connector, 
      { headers: this.headers }).toPromise();

  }


}


