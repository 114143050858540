interface Date {
  pad2: (n) => string;
  YYYYMMDDHHMMSS: () => string;
};

Date.prototype.pad2 = pad2;
Date.prototype.YYYYMMDDHHMMSS = YYYYMMDDHHMMSS;

function pad2(n) {  // always returns a string
  return (n < 10 ? '0' : '') + n;
}

function YYYYMMDDHHMMSS() {

  return this.getFullYear() +
    pad2(this.getMonth() + 1) +
    pad2(this.getDate()) +
    pad2(this.getHours()) +
    pad2(this.getMinutes()) +
    pad2(this.getSeconds());
}
