import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { AuthLoadService } from '../auth/auth-load/auth-load.service';
import { AuthGuardService } from '../auth/auth-guard/auth-guard.service';
import { AuthResolverService } from '../auth/auth-resolver/auth-resolver.service';
import { AuthCallbackComponent } from '../auth/auth-callback/auth-callback.component';
import { SilentCallbackComponent } from '../auth/silent-callback/silent-callback.component';

import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { DemoComponent } from './pages/demos/demos.component';
import { BotTestComponent } from './pages/bot/bot.component';
import { BotAppComponent } from './pages/botapp/botapp.component';
import { BotTeamsTabComponent } from './pages/teamstab/teamstab.component';

import { SuccessComponent } from './pages/success/success.component';

import { LabComponent } from './pages/lab/lab.component';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuardService],
        resolve: [AuthResolverService],
        children: [
          {
            path: '', redirectTo: 'dashboard/gettingstarted',
            data: {
              privilege: 'User'
            },
            pathMatch: 'full'
          },
            //{ path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
            { path: 'elements', loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)},
            { path: 'forms', loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule)},
          {
            path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule),
            canLoad: [AuthLoadService],
          },
            {
              path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule),
              canLoad: [AuthLoadService],
              data: {
                privilege: 'Nobody'
              }},
            {
              path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
              data: {
                privilege: 'User'
            }},
            { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule)},
            { path: 'extras', loadChildren: () => import('./extras/extras.module').then(m => m.ExtrasModule)},
            // Api Demo
            { path: 'api', loadChildren: () => import('./api/api.module').then(m => m.ApiModule) },

          {
            path: 'bots', loadChildren: () => import('./bots/bots.module').then(m => m.BotsModule),
            data: {
              privilege: 'User'
            }
          },
          {
            path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
            data: {
              privilege: 'User'
            }
          },

          {
            path: 'connectors', loadChildren: () => import('./connectors/connectors.module').then(m => m.ConnectorsModule),
            data: {
              privilege: 'User'
            }
          },
          {
            path: 'organization', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
            data: {
              privilege: 'User'
            }
          },
          {
            path: 'playbook', loadChildren: () => import('./playbook/playbook.module').then(m => m.PlaybookModule),
            data: {
              privilege: 'User'
            }
          },

          { path: 'setup', loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule) },

        ]
    },

    // Not lazy-loaded routes
    { path: 'lock', component: LockComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'success', component: SuccessComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

  { path: 'lab', component: LabComponent },
  { path: 'demos/:id', component: DemoComponent },
  { path: 'bottest/:id', component: BotTestComponent },
  { path: 'botapp/:id', component: BotAppComponent },
  { path: 'teamstab/:id', component: BotTeamsTabComponent },

    {
      path: 'auth-callback',
      component: AuthCallbackComponent
    },

    {
      path: 'silent-callback',
      component: SilentCallbackComponent
    },

    // Not found
  {
    path: '**', redirectTo: 'dashboard/gettingstarted',
    data: {
      privilege: 'User'
    },
    pathMatch: 'full'
  }

];
