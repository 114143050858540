import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Observable, Observer } from 'rxjs';

@Pipe({
  name: 'messageTime',
  pure: false
})
export class MessageTimePipe implements PipeTransform, OnDestroy {
  private readonly async: AsyncPipe;

  value: Date;
  timer: Observable<string>;
  isDestroyed: boolean;

  constructor(ref: ChangeDetectorRef) {
    this.async = new AsyncPipe(ref);
  }

  transform(obj: any, args?: any[]): any {

    if (obj === null) {
      return '';
    }

    if (obj instanceof Date) {
      this.value = obj;
    } else {
      this.value = new Date(obj);
    }

    if (!this.timer) {
      this.timer = this.getObservable();
    }

    return this.async.transform(this.timer);
  }

  public ngOnDestroy() {
    this.isDestroyed = true;
  }




  private getObservable() {
    return new Observable<string>((observer: Observer<string>) => {
      setInterval(() => {

        let result: string;
        // current time
        let now = new Date().getTime();

        // time since message was sent in seconds
        let delta = (now - this.value.getTime()) / 1000;

        // format string
        if (delta < 10) {
          result = 'Just now';
        }
        else if (delta < 60) { // sent in last minute
          result = 'Seconds ago';
        }
        else if (delta < 3600) { // sent in last hour
          result = Math.floor(delta / 60) + ' minutes ago';
        }
        else if (delta < 86400) { // sent on last day
          result = Math.floor(delta / 3600) + ' hours ago';
        }
        else { // sent more than one day ago
          result = Math.floor(delta / 86400) + ' days ago';
        }
        observer.next( result );

      }, 1000);
    });


  };
}

