import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  public title: string;
  public message: string;
  public downloadUrl: string;
  public downloadText: string;

  constructor(
    private actRoute: ActivatedRoute,
  ) {

}

  ngOnInit() {
    this.actRoute.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        this.title = params.title;
        this.message = params.message;
        this.downloadUrl = params.downloadUrl;
        this.downloadText = params.downloadText;
      }
    );
 }

}
